import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ModalController, Platform, AlertController } from '@ionic/angular';
import { RestService } from '../services/rest.service';
import { NewStatisticsService } from '../services/newstatistics.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { EventsComponent } from '../components/events/events.component';


//https://github.com/apache/cordova-plugin-screen-orientation

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    return Object.keys(value)//.map(key => value[key]);
  }
}
@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.page.html',
  styleUrls: ['./home-new.page.scss'],
})
export class HomeNewPage implements OnInit {
  @ViewChild(EventsComponent, {static : false}) child : EventsComponent;
  
  objectKeys = Object.keys;
  stringToNumber = Number.parseInt;
  mathRound = Math.round;
  JSON = JSON;
  sizeUpper: string;
  demo = false;
  blanes = false;
  airColor = "rgb(0, 230, 0)";
  airQuality: any;
  happy = "happy";
  calidad = "Buena";
  gridVertical: any;
  gridHorizontal: any;
  easter = false;
  eventFire: boolean = false;
  localhost: boolean = false;
  interval: any;
  videosUrl: any;
  videoSlider: any;
  videoSliderFile: any;
  event1: any;
  event2: any;
  /* intervalEvents: NodeJS.Timer; */
  intervalEvents: any;
  videoFileName: any;

  //Añadido Banner Granada
  mac_granada: any = [
    '12:12:12:12:12:12',
    '01:00:01:00:01:00',
    '00:a5:4c:68:30:ae',
    '00:a2:e5:68:93:a5',
    'a4:6b:b6:a9:f3:9e',
    '00:a1:ec:68:b2:e4',
    '00:a2:e5:68:92:e7',
    '00:a1:ec:68:b3:ae',
    'a4:6b:b6:a9:ef:75',
    '1c:69:7a:00:ab:84'
  ];

  bannerBackgroundColor: any;
  color_corp: any;
  heigth_slider: any;

  constructor(public rest: RestService,public statisticals: NewStatisticsService, public screenOrientation: ScreenOrientation, 
    public platform: Platform, private router: Router, private translateService: TranslateService,  
    private modalCtrl: ModalController, public alertController: AlertController) {

    //Color Corporativo
    this.color_corp = this.rest.colorCorp ? this.rest.colorCorp : '#eee';

    //Subida Alto Slider Video (BLANES PARA VER SUBTITULOS)
    this.heigth_slider = '28%';
    if (this.rest.mac === "blanesnew"){
      this.heigth_slider = '37%';
    }

    // GUARDAR EN LOCALSTORAGE PARAMETROS PARA LOGIN AUTOMATICO AL REFRESCO DE PANTALLA
    if (this.rest.loaded == true && this.rest.mac == 'rincon'){
      this.router.navigate(["/iframe"], {queryParams: {link: 'https://www.turismoenrincon.es/'}});
    }
    if (this.rest.mac == 'Cantur' || this.rest.mac == '1c:69:7a:ac:3c:f0' || this.rest.mac == '6c:94:66:a7:93:ce'){
      this.router.navigate(["/iframe"], {queryParams: {link: 'https://www.cantur.net/santander/Tourist_php/piCarrusel.php'}});
      }

    //Añadido al Refrescar APP se redirija a la HOME
    if (Object.keys(this.rest.response).length === 0){    
        this.router.navigateByUrl('/');
        this.rest.cachedURL = '/home-new';
    }

    this.rest.onChangeLanguageNew(this.rest.usedLang ? this.rest.usedLang : 1, this.rest.codeUsedLang ? this.rest.codeUsedLang : 'es' , -1);

    console.log('kevinNewHome');
    this.sizeUpper = this.rest.size.charAt(0).toUpperCase() + this.rest.size.slice(1);
    this.gridVertical = this.rest.response.items[this.sizeUpper + 'Vertical'];
    
    //Comprobar que vengan estilos del JSON para que no pete APP a su creacion desde CMS -- Temporal hasta arreglo desde CMS
    const toolBarStyle = this.rest.response.items[this.sizeUpper + 'Vertical'].subtoolbar;
    if (toolBarStyle.show && toolBarStyle.style === undefined){
        const toolBarStyleJson = {
          "fontSize": 0.8,
          "fontColor": "#FFFFFF",
          "fontFamily": "Helvetica",
          "backgroundColor": "#F5F5F5",
          "backgroundColorElement": "#3a99d7",
          "shawdow": "0px 0px 0px rgba(0,0,0,1) transparent",
          "fontBold": "normal",
          "fontItalic": "normal",
          "fontUnderline": "none"
        }
        toolBarStyle.style = toolBarStyleJson;      
    }

    //Comprobar que vengan estilos del JSON para que no pete APP a su creacion desde CMS -- Temporal hasta arreglo desde CMS
    const bannerStyle = this.rest.response.items[this.sizeUpper + 'Vertical'].banner;
    if (bannerStyle.show && bannerStyle.style === undefined){
        const bannerStyleJson = {
          "fontSize": 0.8,
          "fontColor": "#FFFFFF",
          "fontFamily": "Helvetica",
          "backgroundColor": "#F5F5F5",
          "backgroundColorElement": "#3a99d7",
          "backgroundColorExpand": "#025c81",
          "shawdow": "0px 0px 0px rgba(0,0,0,1) transparent",
          "fontBold": "normal",
          "fontItalic": "normal",
          "fontUnderline": "none"
        }
        bannerStyle.style = bannerStyleJson;      
    }

    this.gridHorizontal = this.rest.response.items[this.sizeUpper + 'Horizontal'];

    if(this.gridVertical.banner.show == 'true'){
      this.bannerBackgroundColor = this.gridVertical.banner.style.backgroundColor;
    }

    if(this.gridVertical.slider == true){
      if(this.gridVertical.slider.video){
        this.videosUrl = Object.entries(this.gridVertical.slider.videosUrl).reduce((_videos, [_key, {url, time}]: any) => {
          return [
            ..._videos,
            {
              url: url,
              time: time
            }
          ]
        }, []);
      }
    }
    this.event1 = {
      image: '',
      title: '',
      content: ''
    };
    this.event2 = {
      image: '',
      title: '',
      content: ''
    };

    /*
    const url = window.location.href;
    console.log("LOCALHOST: ", url.includes('localhost'));
    console.log("LOOK HERE URL: ", window.location.href);

    this.localhost = url.includes('localhost/');
    const test = (this.gridVertical.slider.videosUrl['1'].url).split('/');
    this.videoFileName = "http://localhost/img/" + test[test.length-1]
    console.log("TEST: ", this.videoFileName);
    */
    
  }



  ngOnInit() {

    const fullRemoteVideoPath = (this.gridVertical.slider.videosUrl['1'].url).split('/');
    const fullLocalVideoPath = "http://localhost/img/" + fullRemoteVideoPath[fullRemoteVideoPath.length-1];
    const result = this.rest.checkFileExist(fullLocalVideoPath);
    if (result == true) {
        console.log('SCRIPT-HOME: Slider Local')
        this.videoFileName = fullLocalVideoPath;
    } else {
        console.log('SCRIPT-HOME: Slider Remoto');
        this.videoFileName = this.gridVertical.slider.videosUrl['1'].url;
    }
    
    this.rest.pointinterestid = null;
    let clientId = this.rest.response.client;
    this.statisticals.clientId = clientId;
    if(!this.statisticals.counter){
      this.statisticals.startNewSession();
      this.statisticals.mac = this.rest.mac;
    }
    if(this.rest.blanes == true){
      this.blanes = true;
    }
    this.rest.airQuality().subscribe((air: any) =>{
      this.airQuality = air[0].CO;
      this.airQuality.value = Math.round(this.airQuality.value * 100) / 100;
      switch(true) {
        case this.airQuality.value < 500:
          this.happy = "happy";
          this.calidad = "Buena";
          this.airColor = "rgb(0, 230, 0)";
          break;
        case this.airQuality.value > 500 && this.airQuality.value < 1500:
          this.happy = "happy"
          this.calidad = "Regular";
          this.airColor = "rgb(215, 125, 8)";
          break;
        case this.airQuality.value > 1500 && this.airQuality.value < 2000:
          this.happy = "sad"
          this.calidad = "Desfavorable";
          this.airColor = "rgb(230, 0, 0)";
          break;
        case this.airQuality.value > 2000:
          this.happy = "sad"
          this.calidad = "Desfavorable";
          this.airColor = "rgb(65, 15, 113)";
          break;
        default:
          this.happy = "happy"
          this.calidad = "Buena";
          this.airColor = "rgb(0, 230, 0)";
      }
     }, error => {
      console.log("No se ha podido obtener la calidad de aire");
    })
  }

  ionViewDidEnter(){
    if (this.child !== undefined){
      this.child.initSwiper();
    } 

    if (this.platform.isPortrait()) {
      console.log("i am portrait!!!")
    }

    this.statisticals.device = 'desktop';
    if (this.platform.is('mobile')) {
      // This will only print when on iOS
        this.statisticals.device = 'mobile';
    }
    if(this.rest.demo == true){
      this.demo = true;
    }
    if(this.rest.size == 'mobile'){
      let homeButton = document.getElementById('homeButton') 
      homeButton.classList.add('toolbar-element-home-mobile');
      homeButton.classList.remove('toolbar-element-home');
    }

    console.log(Object.entries(this.gridVertical.slider.videosUrl).length);
    if(this.gridVertical.slider.video && Object.entries(this.gridVertical.slider.videosUrl).length > 1){
      this.videoSlider = window.document.getElementById('slider');
      this.nextVideo();
    }
    const itemEvent =  window.document.getElementById('eventDinamyc');
    console.log(itemEvent);
    if(itemEvent){
      const itemId = JSON.parse(itemEvent.dataset.itemkey);
      console.log(itemId);
      const eventDatas = itemId.reduce((_allEvents, _eventId) => {
        const allEvents = this.rest.response.datas['event' + _eventId].events.reduce((_eventDatas, _event) => {
          return [..._eventDatas, {
            image: `no-repeat center/contain url(${_event.foto})`,
            title: _event.title,
            content: _event.description
          }]
        }, []);
        return [..._allEvents, ...allEvents];
      }, []);
      console.log(eventDatas);
      this.dinamycEvent(itemEvent, eventDatas);
    }
  }

  ionViewWillLeave(){
    window.clearTimeout(this.intervalEvents);
    window.clearTimeout(this.interval);
  }

  dinamycEvent(itemEvent, eventDatas){
    let eventNumber = parseInt(itemEvent.dataset.eventnumber);
    if(eventNumber > eventDatas.length - 2){
      eventNumber = 0;
    }
    this.event1 = {
      image: eventDatas[eventNumber].image,
      title: eventDatas[eventNumber].title,
      content: eventDatas[eventNumber].content
    };
    this.event2 = {
      image: eventDatas[eventNumber + 1].image,
      title: eventDatas[eventNumber + 1].title,
      content: eventDatas[eventNumber + 1].content
    };
    itemEvent.dataset.eventnumber = eventNumber + 2;
    this.intervalEvents = setTimeout(() => {
      this.dinamycEvent(itemEvent, eventDatas);
    }, 15000);
  }

  async nextVideo(){
    if(!this.eventFire){
      const firstVideo: any = window.document.querySelector(`ion-slide[data-swiper-slide-index='0'] > video`);
      console.log(firstVideo);
      if(firstVideo !== null){
        firstVideo.play();
        this.eventFire = true;
      }else{
        this.interval = setTimeout(() => {
          this.nextVideo();
        }, 1000);
        return '';
      }
    }
    const slidesIndex = await this.videoSlider.getActiveIndex();
    const slidesIndexElement = slidesIndex > this.videosUrl.length 
                              ? 0 
                              : slidesIndex - 1;
    const slidesTime = typeof this.videosUrl[slidesIndexElement] !== undefined
                      ? this.videosUrl[slidesIndexElement].time * 1000
                      : 7777;
    console.log(slidesIndexElement);
    /* const videoCurrent: any = window.document.querySelector(`ion-slide[data-swiper-slide-index='${slidesIndexElement}'] > video`);
    videoCurrent.play(); */
    const videosSlider: any = window.document.querySelectorAll(`ion-slide[data-swiper-slide-index='${slidesIndexElement}'] > video`);
    [...videosSlider].map(_video => {
      _video.muted = true;
      _video.play();
      return '';
    });
    this.interval = setTimeout(() => {
      this.videoSlider.slideNext(1000);
      
      this.nextVideo();
    }, slidesTime);
  }

  appHeightStyle(orientation: string){
    const sizeApplication = this.rest.sizeApplication;
    const subtoolbarCheck = this['grid' + orientation].subtoolbar.show;
    const bannerWeatherCheck = this['grid' + orientation].banner.show;
    const sliderCheck = this['grid' + orientation].slider.show;
    if(subtoolbarCheck && bannerWeatherCheck && sliderCheck){
        return sizeApplication[this.rest.size].subtoolbarbannervideo[orientation];
    }
    if(subtoolbarCheck && bannerWeatherCheck){
        return sizeApplication[this.rest.size].subtoolbarbanner[orientation];
    }
    if(subtoolbarCheck && sliderCheck){
        return sizeApplication[this.rest.size].subtoolbarvideo[orientation];
    }
    if(bannerWeatherCheck && sliderCheck){
        return sizeApplication[this.rest.size].bannervideo[orientation];
    }
    if(subtoolbarCheck){
        return sizeApplication[this.rest.size].subtoolbar[orientation];
    }
    if(bannerWeatherCheck){
        return sizeApplication[this.rest.size].banner[orientation];
    }
    if(sliderCheck){
        return sizeApplication[this.rest.size].video[orientation];
    }
    return sizeApplication[this.rest.size].normal[orientation];
  }

  appBackgroundStyle(orientation: string){
    if(this['grid' + orientation].configuration.lang_selector === 'flag'){
      return {
        'background': `url(${this['grid' + orientation].configuration.url})`
      };
    }
    return {
      'background': `linear-gradient(${this['grid' + orientation].configuration.color_direction}, ${this['grid' + orientation].configuration.color_start} 0%, ${this['grid' + orientation].configuration.color_end} 100%)`
    };
  }

  appSizeStyle(orientation: string){
    const {appHeight, appMarginTop} = this.appHeightStyle(orientation);
    return {
      'height': appHeight,
      'margin-top': appMarginTop
    };
  }

  clickEvent(id){
    this.router.navigate(["/events-news"], {queryParams: {eventid: id}});
  }

  clickItem(rowNumber, itemKey){
    const itemDemoText = this.gridVertical.items[rowNumber].itemsRow[itemKey].text;
    const itemModuleDatas = this.gridVertical.items[rowNumber].itemsRow[itemKey].module;

    console.log("Module: ", itemModuleDatas)
    
    if(itemModuleDatas.type === 'registerUser'){
      this.router.navigate(["/interactive-choice"]);
    }
    if(itemModuleDatas.type === 'routes'){
      this.router.navigate(["/interactive-choice"], {queryParams: {fromServer: itemModuleDatas.id}});
    }
    if(itemModuleDatas.type === 'void' && this.demo == false){
      this.router.navigate(["/map-new"], {queryParams: {id: 'mock'}});
    }
    if(itemModuleDatas.type === 'void' && this.demo == true){
      console.log('im a demo '+itemDemoText)
      if (itemDemoText == "Ayuntamiento de Almuñecar"){
        this.router.navigate(["/iframe"], {queryParams: {id: 'ada'}});
      }
      if (itemDemoText == "Turismo Almuñecar"){
        this.router.navigate(["/iframe"], {queryParams: {id: 'ta'}});
      }
      if (itemDemoText == "Trámites de la Administración"){
        this.router.navigate(["/list-new"], {queryParams: {id: 'gt'}});
      }
      if (itemDemoText == "Callejero"){
        this.router.navigate(["/list-new"], {queryParams: {pointinterestid: 11}});
      }
    }
    if(itemModuleDatas.type === 'rout'){
    this.router.navigate(["/routes"], {queryParams: {routeid: 1}});
    }
    if(itemModuleDatas.type === 'storeVendor'){
      this.router.navigate(["/product-new"], {queryParams: {storevendorid: itemModuleDatas.id}});
    }
    // if(itemModuleDatas.type === 'routes'){
    //   this.router.navigate(["/list-new"], {queryParams: {routetypeid: itemModuleDatas.id}});
    // }
    if(itemModuleDatas.type === 'multiLevel'){
      this.router.navigate(["/multi-level"], {queryParams: {multiLevelid: itemModuleDatas.id}});
    }
    if(itemModuleDatas.type === 'pointsInterest'){
      this.router.navigate(["/list-new"], {queryParams: {pointinterestid: itemModuleDatas.id}});
    }
    //Añadido enlace a punto de interes directo
    if(itemModuleDatas.type === 'pointsInterestID'){
      this.router.navigate(["/product-new"], {queryParams: {pointinterestid_id: itemModuleDatas.id}});
    }
    //Añadido padre de tipos de puntos de interes
    if(itemModuleDatas.type === 'parentTypePointsInterest'){
      this.router.navigate(["/list-new"], {queryParams: {parentpointinterestid: itemModuleDatas.id}});
    }
    //Añadido para Galeria
    if(itemModuleDatas.type === 'gallery'){
      this.router.navigate(["/list-new"], {queryParams: {gallery: itemModuleDatas.id}});
    }
    if(itemModuleDatas.type === 'callejero'){
      this.router.navigate(["/map-new"], {queryParams: {streetmap: 'ok'}});
    }
    if(itemModuleDatas.type === 'rss'){
      this.router.navigate(["/rss-new"], {queryParams: {rssId: JSON.stringify(itemModuleDatas.id)}});
    }
    if(itemModuleDatas.type === 'events'){
      this.router.navigate(["/list-new"], {queryParams: {events: 'all'}});
    }
    if(itemModuleDatas.type === 'news'){
      this.router.navigate(["/list-new"], {queryParams: {news: 'all'}});
    }
    if(itemModuleDatas.type === 'event'){
      this.router.navigate(["/rss-new"], {queryParams: {eventId: JSON.stringify(itemModuleDatas.id)}});
    }
    if(itemModuleDatas.type === 'pdf'){
      if(itemModuleDatas.menu == '1'){
        this.router.navigate(["/list-new"], {queryParams: {pdfId: itemModuleDatas.id}});  
      }
      else{
        this.router.navigate(["/pdf"], {queryParams: {pdfId: itemModuleDatas.id}});
      }
    }
    if(itemModuleDatas.type === 'link'){
      if(itemDemoText == "Calidad del Aire"){
        this.router.navigate(["/aire"]);
      }
      else{
        try {  
          const links = JSON.parse(itemModuleDatas.data);
          let link = links[this.rest.codeUsedLang];
          if (link == ''){
            link = links['es'];
          }
          this.router.navigate(["/iframe"], {queryParams: {link: link, id: itemModuleDatas.id}});
        } catch (e) {  
          this.router.navigate(["/iframe"], {queryParams: {link: itemModuleDatas.data, id: itemModuleDatas.id}});
        }
      }
    }
    if(itemModuleDatas.type === 'selfie'){
      if(this.rest.selfie == false){
        
        this.router.navigate(["/iframe"], {queryParams: {id: 'mt'}});
      }
      else{
        this.router.navigate(["/selfie"], {queryParams: {id: 'mock'}});
      }
    }
  }

  backgroundTextOption(itemType){
    if(itemType !== "event"){
      return "linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0) 100%)";
    }
    return "";
  }

}
