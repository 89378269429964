import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {createTranslateLoader} from '../../app/app.module';
import { HomeNewPage } from './home-new.page';

import { EventsModule } from '../components/events/events.module';
import { SubToolBarModule } from '../components/subtoolbar/subtoolbar.module';
import { HeaderModule } from '../components/header/header.module';
import { WeatherQRModule } from '../components/weatherQR/weatherqr.module';

const routes: Routes = [
  {
    path: '',
    component: HomeNewPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EventsModule,
    SubToolBarModule,
    HeaderModule,
    WeatherQRModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
  })
  ],
  exports: [HomeNewPage],
  declarations: [HomeNewPage]
})
export class HomeNewPageModule {}
