import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Autoplay, Navigation, Pagination]);

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})

export class EventsComponent {
  @Input() swipers!: any;
  @Input() module!: any;
  eventsSwiper: any;
  newsSwiper: any;

  constructor(private router: Router) {
    this.newsSwiper = [];
  }

  initSwiper(): void {
      const swipers = Array.from(document.getElementsByClassName('swiper-container') as HTMLCollectionOf<any>);
      swipers.forEach(element => {
        if (!element.classList.contains('swiper-container-initialized')){
          const test = new Swiper(element, {
            spaceBetween: 15,
            slidesPerView: 2,
            loop: false,
            init: true,
            direction: 'vertical',
            autoplay: {
              disableOnInteraction: false
            }
          });
          this.newsSwiper.push(test);
        }
        if (this.newsSwiper){
          this.newsSwiper.forEach(element => {
            element.init();
            element.autoplay.stop();
            element.autoplay.start();
            element.slideTo(0);
          });
        }
      });
  }

  clickEvent(type, id){
    if (type == "events"){
      this.router.navigate(["/events-news"], {queryParams: {eventid: id}});
    }
    if (type == "news"){
      this.router.navigate(["/events-news"], {queryParams: {newsid: id}});
    }  
  }

}