import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class NodeJSService {
  private url = 'https://nodejsaws.duckdns.org:3333';
  private urlVOIP = 'https://nodejsaws.duckdns.org:0000';

  socketIO: any;
  socketVOIP: any;

  constructor() {
    // this.socketIO = socket;
  }

  initServer() {
    console.log('initializing node server...');
    this.socketIO = io.connect(this.url);
  }

  initServerVOIP() {
    this.socketVOIP = io.connect(this.urlVOIP);
  }

  cancelServerVOIP() {
    this.socketVOIP.disconnect();
  }

  readLogs() {
    this.socketIO.on('log', log => {
      console.log('log: ' + JSON.stringify(log));
    });
  }

  sendOrder(contentID, order: any) {
    console.log('sending order ' + JSON.stringify(order.scheduled_date));
    // this.socketIO.emit('request_server', 'new-order', contentID, order);
    this.socketIO.emit('new-order', contentID, order);
  }

  sendMessage(message: string) {
    console.log('sending message ' + message);
    this.socketIO.emit('new-message', message);
  }
}
