import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as constants from '../constants';
import { ToastController } from '@ionic/angular';
import { Statistics } from '../models/Statistics';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class NewStatisticsService {

  public httpOptions: any;
  counter:number;
  selfie:number;
  intvl:any;
  clientId:number;
  points:any;
  sesh:Statistics;
  rest:RestService;
  clicks:number;
  mac:string;
  device: string;
 

  constructor(public http: HttpClient, private toastController: ToastController) {
      this.sesh = {
          client_id: 0,
          counter: 0,
          selfie: 0,
          clicks: 0,
          mac: null,
          device: null,
          language: null,
          points: []
      }
      
      //Inicializo para que no pete al navegar en la APP cuando entras directamente a un punto de interes
      this.points = [];
  }

   //____________________________________________________________________//
  //____________________STATISTICS NEW CMS SMART CITY___________________//
  
//Start a new statistics session
public startNewSession(){

  console.log('new stats sesh started');
    console.log(this.sesh);
    this.sesh = null;
    this.counter = 0;
    this.selfie = 0;
    this.points = [];
    this.clicks = 0; //Añadir comprobacion de clicks
    this.mac = ''; //Comprobacion de MAC dispositivo
    this.device = '';
    this.intvl = setInterval(() => {
      this.counter++
    }, 1000);
  }
//add a Selfie taken 
 public addSelfie(){
    this.selfie++
  }
//add a point of interest clicked
 public addPoint(Point){
   console.log("Servide: ", Point)
   console.log("this.points: ", this.points)
    this.points.push(Point)
  }

public addClick(){
  this.clicks++;
}


//send to cms and close session
public closeSession(lang){
  this.sesh = {
      client_id: this.clientId,
      counter: this.counter,
      selfie: this.selfie,
      clicks: this.clicks,
      mac: this.mac,
      device: this.device,
      language: lang,
      points: this.points
  }

  this.sendStats(this.sesh).subscribe(response => {
    console.log(response);
});;
  
}
public sendStats(sesh){
    console.log(sesh)
    return this.http.post(constants.apiUrl + 'api/send-statistics', { sesh }, this.httpOptions);
}

}