
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'selector-idioma-standby',
  templateUrl: 'selector-idioma-standby.page.html',
  styleUrls: ['selector-idioma-standby.page.scss'],
})

export class SelectorIdiomaStandBy implements OnInit {

  gridVertical: any;
  sizeUpper: any;
  logo: string;
  timeout: any;
  size: any;
  activeLangs: any;
  languages: any;

  constructor(
    public rest: RestService,
    private modalCtrl: ModalController) {

    //Añadido Multi-Idioma selector desde CMS
    this.activeLangs = rest.getActiveLangs();
    this.size = 4;
    if (this.activeLangs.length <= 4){
      this.size = 6;
    }

    this.sizeUpper = this.rest.size.charAt(0).toUpperCase() + this.rest.size.slice(1);
    this.gridVertical = this.rest.response.items[this.sizeUpper + 'Vertical'];
    this.logo = this.gridVertical.toolbar.logo.url;
    
    this.timeout = setTimeout(() => {
      console.log("look here: ", this.modalCtrl)
      if (this.modalCtrl){
        try{
          this.modalCtrl.dismiss();
        }catch{
          console.warn("Seleccion de idiomas ya cerrado")
        }
      }
    }, 15000);
  }

  ngOnInit(): void {
    console.log("Iniciando Avuso Legal: ");
  }

  closeModalAntonio(): void {
    if (this.modalCtrl){
      clearTimeout(this.timeout);
      this.modalCtrl.dismiss();
    }
  }

  selectLanguage(a,b,c): void {
    this.closeModalAntonio();
    this.rest.onChangeLanguageNew(a, b, c);
  }


}


