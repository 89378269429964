import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ModalPageModule } from './modal/modal.module';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Hotspot } from '@ionic-native/hotspot/ngx';
import { File } from '@ionic-native/file/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DecimalPipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Title } from '@angular/platform-browser';
import { Network } from '@ionic-native/network/ngx';
import { EmailInputPageModule } from './modules/email-input/email-input.module';
import { BananaPageModule } from './modules/banana/banana.module';
import { AvisoLegalPageModule } from './modules/aviso-legal/aviso-legal.module';
import { SelectorIdiomaStandByModule } from './modules/selector-idioma-standby/selector-idioma-standby.module';
import { HomeNewPageModule } from './home-new/home-new.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/langs/', '.json');
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class SharedModule {}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({animated: false,hardwareBackButton : false}), IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    EmailInputPageModule,
    BananaPageModule,
    ModalPageModule,
    SelectorIdiomaStandByModule,
    AvisoLegalPageModule,
    PdfViewerModule,
    HomeNewPageModule,
    TranslateModule.forRoot({ // <--- add this
    loader: { // <--- add this
      provide: TranslateLoader, // <--- add this
      useFactory: (createTranslateLoader),  // <--- add this
      deps: [HttpClient] // <--- add this
    } // <--- add this
  }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }) ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    StatusBar,
    SplashScreen,
    File,
    Title,
    Network,
    CallNumber,
    DecimalPipe,
    ScreenOrientation,
    IonicStorageModule,
    AndroidPermissions,
    InAppBrowser,
    Hotspot,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
