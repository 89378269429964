import { Injectable } from '@angular/core';
import * as constants from '../constants';
import { AlertController, ToastController } from '@ionic/angular';
import { UtilsService } from '../services/utils.service';
import * as io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class WebrtcService {

  /*
  loginPage = document.querySelector('#loginPage') as HTMLDivElement;
  usernameInput = document.querySelector('#usernameInput') as HTMLInputElement;
  loginBtn = document.querySelector('#loginBtn') as HTMLButtonElement;
  callPage = document.querySelector('#callPage') as HTMLDivElement;
  callToUsernameInput = document.querySelector('#callToUsernameInput') as HTMLInputElement;

  answerBtn = document.querySelector('#answerBtn') as HTMLButtonElement;
  //answerBtn.disabled = true;
  hangUpBtn = document.querySelector('#hangUpBtn') as HTMLButtonElement;
*/

  callBtn = document.querySelector('#callBtn') as HTMLButtonElement;
  remoteAudio = document.getElementById('remote-audio') as HTMLVideoElement;


  //localAudio = document.querySelector('#localVideo') as HTMLVideoElement;
  //remoteAudio = document.querySelector('#remoteVideo') as HTMLVideoElement;

  recognition;
  extensions;
  status = 'Unregistered';
  callState = constants.STATE_HUNG;
  socket;
  yourConn;
  myStream;
  data;
  offer;
  audio;
  connectInterval
  ringInterval;
  name;
  connectedUser;
  conn;
  offerName;
  answered = false;
  speechRecognition;
  speechGrammarList;
  intervalRestart;
  alert;
  toast;
  callbtn;
  words = ['llamar', 'llama', 'yama', 'ama', 'hama'];
  tones = 0;
  maxTones = 6;
  hangupCause = 'busy';
  callBtnPosition = 'up';

  //url = 'http://localhost:3000';
  url = 'https://nodejsawsdqagro.duckdns.org:3333';

  STUN = {
    urls: 'stun:stun.l.google.com:19302'
  };

  TURN = {
    urls: 'turn:turn.bistri.com:80',
    credential: 'homeo',
    username: 'homeo'
  };

  iceServers = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' }]
  };

  destinys = [
    {
      ext: 1,
      destiny: 3
    },
    {
      ext: 2,
      destiny: 4
    },
    {
      ext: 3,
      destiny: 1
    },
    {
      ext: 4,
      destiny: 2
    }, {
      ext: 5,
      destiny: 6
    }, {
      ext: 6,
      destiny: 5
    }, {
      ext: 7,
      destiny: 8
    }, {
      ext: 8,
      destiny: 7
    },
  ];

  constructor(public alertCtrl: AlertController, private toastController: ToastController, public utils: UtilsService) { }

  ngOnInit(): void {
  }

  start() {

    console.log('on start connect ')
    this.socket = io.connect(this.url, { secure: true })
    this.socket.on('connect', async () => {
      console.log('on connection, MY SOCKET ID ' + this.socket.id);

      this.login(this.name);
      this.connectInterval = setInterval(() => {
        console.error('checking, is registered ' + (this.status == 'Registered'));

        if (this.status != 'Registered') {
          this.login(this.name);
        }
      }, 5000);
    });

    this.socket.on('disconnect', async () => {
      console.log('on disconnect');

      status = 'Unregistered';

    });

    this.socket.on('webrtc_message', async (data) => {
      var jsonMessage = JSON.parse(data);

      console.log('Message... ')
      this.handleMessage(jsonMessage);
    })
  }



  login(name) {
    this.send({
      type: "login",
      name: name
    });
  }

  async presentToastType(message, color, time) {
    try {
      this.toast.dismiss();
    } catch (e) { }

    this.toast = await this.toastController.create({
      message: message,
      color: color,
      duration: time,
      position: (this.callBtnPosition == 'bottom' ? 'top' : 'bottom')
    });
    this.toast.present();
  }


  startVoiceRecognition() {
    console.log('name ' + this.name + ' status ' + this.status);

    if ((this.name == '1' || this.name == '2') && this.status == 'Registered') {
      this.dismissAlert();
      console.log('starting voice recognition...');

      // this.initStartRecognition();
      this.speechRecognition.start();
      /*this.intervalRestart = setInterval(() => {
        console.log('speech es ' + !this.speech)
        if (!this.speech && this.callState == constants.STATE_HUNG) {
          try{
            this.speechRecognition.start();
           //this.initStartRecognition();
          }catch(e){
            console.error('error aqui ' + e);
             //this.initStartRecognition();
            //this.speechRecognition.start();
          }
         
        }
      }, 5000);*/
    }


  }

  stopVoiceRecognition() {
    if (this.speechRecognition) {
      this.speechRecognition.stop();
    }
    clearInterval(this.intervalRestart);

    this.dismissToast();
    // this.dismissAlert();
  }


  talk() {
    this.utils.speech = true;
    if (document.getElementById('micro')) {
      document.getElementById('micro').style.display = 'block';

    }
    this.presentToastType("Diga en voz alta 'llamar' para hacer una llamada", 'primary', 100000);

  }

  isMatch(result): boolean {
    let isMatch = false;
    this.words.forEach(word => {
      // console.log('result ' + result + ' include ' + word + ' include?' + (result.includes(word)));
      if (result.includes(word)) {
        isMatch = true;
        return;
      }
    });
    return isMatch;
  }


  dismissToast() {
    try {
      this.toast.dismiss();
    } catch (error) {

    }
  }

  dismissAlert() {
    try {
      this.alert.dismiss();
    } catch (error) {

    }
  }

  async presentAlertIncoming(peerdisplayname) {
    console.log('targetUser ' + peerdisplayname);
    this.alert = await this.alertCtrl.create({
      cssClass: 'alertCustomCss',
      message: 'Llamada entrante de ' + peerdisplayname,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Rechazar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.hangup();
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            console.log('Confirm Okay');
            this.answer();
          }
        }
      ]
    });
    await this.alert.present();
  }


  getDestiny() {
    var destiny;
    this.destinys.forEach(item => {
      //console.log('soy  ' + item.ext + ' mi id ' + this.response.configuration.extension_id);
      if (item.ext == this.name) {
        console.log('igual  ');
        destiny = item.destiny;
        return false;
      }
    });
    return destiny;
  }


  handleMessage(data) {
    switch (data.type) {
      case "login":
        this.handleLogin(data.success);
        break;
      //when somebody wants to call us 
      case "offer":
        this.handleOffer(data);
        break;
      case "answer":
        this.handleAnswer(data.answer);
        break;
      //when a remote peer sends an ice candidate to us 
      case "candidate":
        this.handleCandidate(data.candidate);
        break;
      case "leave":
        this.handleLeave();
        break;
      default:
        break;
    }
  }

  toggleTrack(stream, type) {
    stream.getTracks().forEach((track) => {
      if (track.kind === type) {
        track.enabled = !track.enabled;
      }
    });
  }

  //alias for sending JSON encoded messages 
  send(message) {
    //attach the other peer username to our messages 
    if (this.connectedUser) {
      message.name = this.connectedUser;
    }

    this.socket.emit('webrtc_message', JSON.stringify(message));

  };

  handleLogin(success) {
    if (success === false) {
      console.error("Oops...try a different username");
    } else {
      this.status = 'Registered';
      // this.startVoiceRecognition();
      this.initStartRecognition();

      console.log('before get usermedia')


      navigator.mediaDevices.getUserMedia({ audio: true }).then((myStream) => {
        console.log('on get usermedia')

        this.myStream = myStream;
        //  this.localVideo.srcObject = this.stream;

        this.initRTC();

      }, (error) => {
        console.log(error);
      });



    }
  };

  initRTC() {
    //using Google public stun server 
    this.yourConn = new RTCPeerConnection(this.iceServers);
    console.log('RTC connection is ' + this.yourConn);

    // setup stream listening
    this.yourConn.addStream(this.myStream); //DEPRECATED

    /*for (const track of this.myStream.getTracks()) {
      console.log('pongo track ' + track)
      this.yourConn.addTrack(track);
    }*/

    //when a remote user adds stream to the peer connection, we display it 
    //DEPRECATED
    this.yourConn.onaddstream = (e) => {
      console.log('on add stream remote stream...')
      this.remoteAudio.srcObject = e.stream;
    };

   /* this.yourConn.ontrack = (e) => {
      console.log('on add stream remote stream... ' + e.streams[0])
      this.remoteAudio.srcObject = e.streams[0];      
    };*/

    // Setup ice handling 
    this.yourConn.onicecandidate = (event) => {
      if (event.candidate) {
        this.send({
          type: "candidate",
          candidate: event.candidate
        });
      }
    };
  }

  initStartRecognition() {


    if ((this.name == '1' || this.name == '2') && this.status == 'Registered') {
      console.log('on start recognition')
      // const words = ['dq agro']; // 'agro', 'bq agro', 'recuadro', 'becuadro', 'becocuadro'];
      const grammar = '#JSGF V1.0; grammar words; public <word> = ' + this.words.join(' | ') + ' ;';

      this.speechRecognition = null;
      this.speechRecognition = new (window['SpeechRecognition'] || window['webkitSpeechRecognition']);
      //this.speechRecognition = new SpeechRecognition();
      // tslint:disable-next-line: new-parens
      const speechGrammarList = window['webkitSpeechGrammarList'];
      const speechRecognitionList = new speechGrammarList(); // || webkitSpeechGrammarList
      speechRecognitionList.addFromString(grammar, 1);
      this.speechRecognition.continuous = false;
      this.speechRecognition.lang = 'es-ES';
      this.speechRecognition.interimResults = false;
      this.speechRecognition.maxAlternatives = 10;

      this.dismissAlert();
      console.log('starting voice recognition...');

      this.speechRecognition.start();
      this.speechRecognition.onstart = () => {
        // document.getElementById('talk').style.display = 'block';
        // this.dismissAlert();
        console.error('started voice recognition...');
        this.utils.speech = true;
        this.talk();
      };
      this.speechRecognition.onspeechstart = () => {
        console.log('starting speech recognition...');
        this.talk();
      };
      this.speechRecognition.onsoundstart = () => {
        // document.getElementById('talk').style.display = 'block';
        console.log('on sound start...');
        this.utils.speech = true;
        document.getElementById('micro').style.display = 'block';
        // document.getElementById('microColor').style['--background'] = 'green';
      };
      this.speechRecognition.onaudioend = () => {
        // document.getElementById('talk').style.display = 'none';
        console.log('on sound end...');
        this.utils.speech = false;
        //this.speechRecognition = null;
        if (this.callState != constants.STATE_CALLING) {
          this.initStartRecognition();
        }
        //this.speechRecognition.start();

        document.getElementById('micro').style.display = 'none';
        //this.presentToastType('Reconociendo voz, espere por favor...', 'primary', 5000);

      };
      this.speechRecognition.onresult = (event) => {
        const result = event.results[0][0].transcript as string;
        result.toLocaleLowerCase();

        console.log('result here ' + result + ' confidence ' + event.results[0][0].confidence);


        if (this.isMatch(result)) {
          const destiny = this.getDestiny();
          console.log('destiny soy ' + destiny);
          this.call(destiny);
        }
      };
      this.speechRecognition.onspeechend = () => {
        //this.speechRecognition.recognition.stop();
        console.error('on speech end');
      };
      this.speechRecognition.onnomatch = (event) => {
        console.log('no match');
      };
      this.speechRecognition.onerror = (event) => {
        console.log('on error aqui');
      };
    }
  }

  //initiating a call
  call(destiny) {
    //var callToUsername = this.callToUsernameInput.value;

    this.stopVoiceRecognition();

    if (this.callState != constants.STATE_CALLING) {
      this.callState = constants.STATE_CALLING;
      // this.callbtn.className = 'btn-call-calling';

      console.log('connecting to ' + destiny);

      //this.conn = this.peer.connect(destinyPeer);
      //this.setConnectionEvents(this.conn);

      //this.callPeer = this.peer.call(destinyPeer, this.localStream);
      //this.setCallEvents(this.callPeer);
      this.presentToastType('Llamando...', 'success', 20000);

      //this.hangUpBtn.disabled = false;
      // this.callBtn.disabled = true;
      this.connectedUser = destiny;

      // create an offer 
      this.yourConn.createOffer((offer) => {
        this.send({
          type: "offer",
          offer: offer
        });

        this.yourConn.setLocalDescription(offer);
      }, (error) => {
        this.callBtn.disabled = false;
        alert("Error when creating an offer " + error);
      });


      setTimeout(() => {
        this.playOutboundRing();
      }, 10);

    }

  }

  answer() {
    this.answered = true;
    this.yourConn.setRemoteDescription(new RTCSessionDescription(this.offer));

    this.stopRing();
    this.callState = constants.STATE_ONLINE;
    //this.callbtn.className = 'btn-call-calling';


    //create an answer to an offer 
    this.yourConn.createAnswer((answer) => {


      this.yourConn.setLocalDescription(answer);

      this.send({
        type: "answer",
        answer: answer
      });

    }, (error) => {
      alert("Error when creating an answer");
    });
  }

  onCall(call) {
    this.stopVoiceRecognition();
    this.playIncomingRing();
    //this.callPeer = call;
    this.presentAlertIncoming(call.peer);
    //this.setConnectionEvents(this.conn);
  }

  onAnswerCall(answer) {
    this.yourConn.setRemoteDescription(new RTCSessionDescription(answer));

    this.callState = constants.STATE_ONLINE;
    this.stopVoiceRecognition();
    //console.log('2 on remotestream ' + remoteStream);
    //this.remoteVideo.srcObject = remoteStream;
    this.stopRing();
  }


  hangup() {
    this.send({
      type: "leave"
    });

    this.handleLeave();
  }

  //when somebody sends us an offer 
  handleOffer(data) {
    this.connectedUser = data.name;
    this.offer = data.offer;
    this.offerName = data.name;
    //this.answerBtn.disabled = false;
    //this.hangUpBtn.disabled = false;

    this.stopVoiceRecognition();
    this.playIncomingRing();
    //this.callPeer = call;
    this.presentAlertIncoming(this.offerName);
  }

  //when we got an answer from a remote user
  handleAnswer(answer) {
    this.onAnswerCall(answer);
  }

  playIncomingRing() {
    const src = '../../assets/sounds/Ring1.mp3';
    this.audio = new Audio(src);
    this.audio.play();
    this.ringInterval = setInterval(() => {
      this.audio.play();
    }, 2000);
  }

  playOutboundRing() {
    console.log('playing interval');
    const src = '../../assets/sounds/RingOutbound.mp3';
    this.audio = new Audio(src);
    this.audio.play();

    if (this.ringInterval) {
      clearInterval(this.ringInterval);
    }

    this.ringInterval = setInterval(() => {
      console.log('ringing interval');

      this.tones++;

      if (this.tones >= this.maxTones) {
        this.hangupCause = 'busy';
        this.hangup();
      } else {
        this.audio.play();
      }
    }, 4000);
  }

  stopRing() {
    try {
      clearInterval(this.ringInterval);
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    } catch (error) {

    }
  }

  //when we got an ice candidate from a remote user 
  handleCandidate(candidate) {
    if (this.answered) {
      if (this.imReceptor()) {
        console.log('soy receptor')
        //this.hangUpBtn.disabled = false;
        //answerBtn.disabled = true;
        //this.callBtn.disabled = true;

        this.callbtn.className = 'btn-call-calling';

        this.yourConn.addIceCandidate(new RTCIceCandidate(candidate));
      }
    } else {
      console.log('offer is ' + this.offerName + ' me ' + name)
      if (this.imSender()) { // candidato para el que llama
        console.log('soy el que llama')

        //this.hangUpBtn.disabled = false;
        //answerBtn.disabled = true;
        //this.callBtn.disabled = true;

        //this.callbtn.className = 'btn-call-calling';

        this.yourConn.addIceCandidate(new RTCIceCandidate(candidate));
      }
    }
  };

  imReceptor() {
    return this.offerName != name;
  }

  imSender() {
    return !this.offerName || this.offerName == name;
  }

  handleLeave() {
    this.tones = 0;
    console.error('on leave')
    this.answered = false;


    this.callState = constants.STATE_HUNG;
    //this.callbtn.className = 'btn-call';

    this.stopRing();
    //this.callPeer.close();
    //this.conn.close();
    this.dismissAlert();
    this.presentToastType('Llamada colgada', 'danger', 2000);

    //this.callbtn.className = 'btn-call';

    this.connectedUser = null;
    this.remoteAudio.srcObject = null;

    this.yourConn.close();
    this.yourConn.onicecandidate = null;
    //this.yourConn.onaddstream = null; //DEPRECATED
    this.yourConn.ontrack = null;
    this.offerName = undefined;
    this.initRTC();

    this.initStartRecognition();
  };
}
