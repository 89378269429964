import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import { ModalController, Platform } from '@ionic/angular';
import { BananaPage } from '../../modules/banana/banana.page';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})

export class HeaderComponent {
    gridVertical: any;
    sizeUpper: string;
    color_corp: any;
    activeLangs: any;
    intervalClock: any;
    clockHour: any;
    clockMinute: any;
    clockDay: any;
    clockMonth: any;
    clockYear:any;
    platano = 0;
    bnna:any;

    toolbarLogo: any;
    toolbarClock: any;
    toolbarLanguage: any;

    constructor(public rest: RestService, private router: Router, private modalCtrl: ModalController) {
        this.sizeUpper = this.rest.size.charAt(0).toUpperCase() + this.rest.size.slice(1);
        this.gridVertical = this.rest.response.items[this.sizeUpper + 'Vertical'];

        //Color Corporativo
        this.color_corp = this.rest.colorCorp ? this.rest.colorCorp : '#eee';

        //Añadido Multi-Idioma selector desde CMS
        this.activeLangs = rest.getActiveLangs();
        this.clockToolbar();

        console.log("LOOK: ", this.gridVertical.toolbar.distribution)
        this.gridVertical.toolbar.distribution.forEach(element => {
            if (element === "logo"){
                this.toolbarLogo = element;
            }
            if (element === "clock"){
                this.toolbarClock = element;
            }
            if (element === "language"){
                this.toolbarLanguage = element;
            }
            
        });
        console.log("DASDAS; ", this.toolbarLogo)

    }

    ionViewDidEnter(){
        this.clockToolbar();
    }

    ionViewWillLeave(){
        window.clearTimeout(this.intervalClock);
    }

    gradientToolbar(orientation: string){
        return `linear-gradient(${this['grid' + orientation].toolbar.color_direction}, ${this['grid' + orientation].toolbar.color_start} 0%, ${this['grid' + orientation].toolbar.color_end} 100%)`;
    }

    timeToHourly(time){
        const dateTime = new Date(time * 1000);
        return `${this.formatDateWithCero(dateTime.getHours())}:${this.formatDateWithCero(dateTime.getMinutes())}`;
    }

    dateNumber(time){
        const dateTime = new Date(time * 1000);
        return dateTime.getDate();
    }

    formatDateWithCero(number){
        return number < 10 ? `0${number}` : number;
    }

    clockToolbar = () => {
        const dateToday = new Date();
        this.clockHour = this.formatDateWithCero(dateToday.getHours());
        this.clockMinute = this.formatDateWithCero(dateToday.getMinutes());
        this.clockDay = this.formatDateWithCero(dateToday.getDate());
        this.clockMonth = this.formatDateWithCero(dateToday.getMonth() + 1);
        this.clockYear = dateToday.getFullYear();
        console.log('clockToolbar', 60000 - (dateToday.getSeconds() * 1000));
        this.intervalClock = setTimeout(this.clockToolbar, 60000 - (dateToday.getSeconds() * 1000));
    }

    goHome(){
        this.router.navigateByUrl('/home-new');
    }

    public async banana(){
        this.platano++
        console.log(this.bnna);
        if(!this.bnna){
        this.bnna = setTimeout( () => {
          this.platano = 0;
          this.bnna = null;
          console.log('cleared')
     }, 3000);}
        
      }
      public async presentBanana(){
        setTimeout( () => {
          modal.dismiss();
          }, 5000);
        const modal = await this.modalCtrl.create({
          component: BananaPage,
          cssClass: 'banana-modal'
        });
      
        await modal.present();
        const { data } = await modal.onDidDismiss();
        this.platano = 0;
    }
}